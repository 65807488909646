<template>
  <div>
    <header class="el-drawer__header nanw_khan">
      <span title="Новый заказ">{{ $t('message.create_currency') }}</span>
      <el-button
        type="success"
        size="small"
        class="mr-1"
        :loading="loadingButton"
        :disabled="loadingButton"
        @click="submit()"
      >{{ $t('message.save') }}</el-button>
      <el-button
        type="primary"
        size="small"
        class="mr-1"
        @click="submit(true)"
      >{{ $t('message.save_and_create_new') }}</el-button>
      <el-button
        type="warning"
        icon="el-icon-close"
        size="small"
        @click="close"
      >{{ $t('message.close') }}</el-button>
    </header>
    <div class="body__modal">
      <el-form ref="form" :model="form" size="small" class="aticler_m stylekhanForm">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item :label="columns.name.title">
              <el-input v-model="form.name" :placeholder="columns.name.title" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item :label="columns.symbol.title">
              <el-input v-model="form.symbol" :placeholder="columns.symbol.title" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item :label="columns.code.title">
              <el-input v-model="form.code" :placeholder="columns.code.title" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item>
              <el-checkbox v-model="form.active">{{ $t('message.active') }}</el-checkbox>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="columns.rate.title">
              <el-input v-model="form.rate" :placeholder="columns.rate.title" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item>
              <el-checkbox v-model="form.reverse">{{ columns.reverse.title }}</el-checkbox>
            </el-form-item>
            <el-form-item :label="columns.reversed_rate.title" v-show="form.reverse">
              <el-input v-model="form.reversed_rate"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import drawerChild from "@/utils/mixins/drawer-child";
import form from "@/utils/mixins/form";

export default {
  mixins: [
    drawerChild,
    form
  ],
  computed: {
    ...mapGetters({
      columns: 'currencies/columns',
      model: 'currencies/model',
      rules: 'currencies/rules'
    })
  },
  methods: {
    ...mapActions({
      save: "currencies/store",
      update: "currencies/update",
      show: "currencies/show",
      empty: "currencies/empty"
    }),
    submit(resetForm = false) {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.loadingButton = true;
          if (this.form.id == null) {
            this.save(this.form)
              .then(res => {
                this.loadingButton = false;
                this.form = res.data.currency;
                this.$alert(res);
              })
              .catch(err => {
                this.$alert(err);
                this.loadingButton = false;
              })
              .finally(() => {});
          } else {
            this.update(this.form)
              .then(res => {
                this.loadingButton = false;
                this.$alert(res);
              })
              .catch(err => {
                this.loadingButton = false;
                this.$alert(err);
              })
              .finally(() => {
                if (resetForm) {
                  this.$refs["form"].resetFields();
                  this.empty();
                }
              });
          }
        }
      });
    },
  }
};
</script>
