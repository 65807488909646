<template>
  <div class="app-container">
    <div class="mytabel table-sm mr-0 ml-0 p-0">
      <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
        <div
          class="
            col-7
            p-0
            align-self-center
            font-weight-bold
            d-flex
            align-items-center
          "
        >
          <h5 class="d-inline mr-2 font-weight-bold">
            {{ $t("message.currency") }}
          </h5>
          <crm-refresh @c-click="refresh()"></crm-refresh>
          <div class="text-center d-flex sorddata ml-3">
            <el-input
              class="ml-3"
              size="mini"
              :placeholder="$t('message.search')"
              prefix-icon="el-icon-search"
              v-model="filterForm.search"
              clearable
            ></el-input>
            <el-date-picker
              class="ml-3"
              size="mini"
              v-model="filterForm.start_date"
              type="date"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              :placeholder="$t('message.start_date')"
            ></el-date-picker>
            <el-date-picker
              class="ml-3"
              size="mini"
              v-model="filterForm.end_date"
              type="date"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              :placeholder="$t('message.end_date')"
            ></el-date-picker>
          </div>
        </div>
        <div
          class="
            col-5
            align-items-center align-self-center
            text-right
            pr-0
            d-flex
            justify-content-end
          "
        >
          <el-button
            class="mr-2"
            size="mini"
            @click="drawer.create.status = true"
            icon="el-icon-circle-plus-outline"
            >{{ $t("message.create") }}</el-button
          >
          <el-dropdown class="mr-2">
            <el-button size="mini" icon="el-icon-setting">
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(column, index) in columns" :key="index">
                <el-checkbox
                  :checked="column.show"
                  @change="column.show = !column.show"
                  >{{ column.title }}</el-checkbox
                >
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <table
        class="table table-bordered table-hover mr-0 ml-0 p-0 bg-white"
        v-loading="loadingData"
      >
        <crm-pagination
          :pagination="pagination"
          @c-change="updatePagination"
        ></crm-pagination>
        <thead>
          <tr>
            <th v-if="columns.id.show">{{ columns.id.title }}</th>
            <th v-if="columns.name.show">{{ columns.name.title }}</th>
            <th v-if="columns.rate.show">{{ columns.rate.title }}</th>
            <th v-if="columns.reversed_rate.show">
              {{ columns.reversed_rate.title }}
            </th>
            <th v-if="columns.symbol.show">{{ columns.symbol.title }}</th>
            <th v-if="columns.created_at.show">
              {{ columns.created_at.title }}
            </th>
            <th v-if="columns.updated_at.show">
              {{ columns.updated_at.title }}
            </th>
            <th v-if="columns.settings.show">{{ columns.settings.title }}</th>
          </tr>

          <tr>
            <th v-if="columns.id.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.id"
                class="id_input"
                :placeholder="columns.id.title"
              ></el-input>
            </th>
            <th v-if="columns.name.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.name"
                :placeholder="columns.name.title"
              ></el-input>
            </th>
            <th v-if="columns.rate.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.rate"
                :placeholder="columns.rate.title"
              ></el-input>
            </th>
            <th v-if="columns.reversed_rate.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.reversed_rate"
                :placeholder="columns.reversed_rate.title"
              ></el-input>
            </th>
            <th v-if="columns.symbol.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.symbol"
                :placeholder="columns.symbol.title"
              ></el-input>
            </th>
            <th v-if="columns.created_at.show">
              <el-date-picker
                size="mini"
                v-model="filterForm.created_at"
                :placeholder="columns.created_at.title"
                type="date"
                :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              ></el-date-picker>
            </th>
            <th v-if="columns.settings.show"></th>
          </tr>
        </thead>
        <transition-group name="flip-list" tag="tbody">
          <tr
            v-for="currency in list"
            :key="currency.id"
            class="cursor-pointer"
          >
            <td v-if="columns.id.show">{{ currency.id }}</td>
            <td v-if="columns.name.show">{{ currency.name }}</td>
            <td v-if="columns.rate.show">{{ currency.rate }}</td>
            <td v-if="columns.reversed_rate.show">
              {{ currency.reversed_rate }}
            </td>
            <td v-if="columns.symbol.show">{{ currency.symbol }}</td>
            <td v-if="columns.created_at.show">{{ currency.created_at }}</td>
            <td v-if="columns.updated_at.show">{{ currency.updated_at }}</td>
            <td v-if="columns.settings.show" class="settings-td">
              <crm-setting-dropdown
                :model="currency"
                name="currencies"
                :actions="actions"
                @edit="edit"
                @delete="destroy"
              ></crm-setting-dropdown>
            </td>
          </tr>
        </transition-group>
      </table>
    </div>

    <el-drawer
      size="50%"
      :visible.sync="drawer.create.status"
      :with-header="false"
      :ref="drawer.create.name"
      :before-close="beforeClose"
    >
      <CrmCreate :drawer-name="drawer.create.name" />
    </el-drawer>
  </div>
</template>
<script>
import CrmCreate from "./components/crm-create";
import { mapGetters, mapActions } from "vuex";
import list from "@/utils/mixins/list";
import drawer from "@/utils/mixins/drawer";

export default {
  mixins: [list, drawer],
  data() {
    return {
      loadingData: false,
      drawer: {
        create: {
          name: "create",
          status: false,
        },
      },
      actions: ["edit", "delete"],
    };
  },
  components: {
    CrmCreate,
  },
  computed: {
    ...mapGetters({
      list: "currencies/list",
      columns: "currencies/columns",
      pagination: "currencies/pagination",
      filter: "currencies/filter",
      sort: "currencies/sort",
    }),
  },
  methods: {
    ...mapActions({
      getCurrencies: "currencies/index",
      updateSort: "currencies/updateSort",
      updateFilter: "currencies/updateFilter",
      updateColumn: "currencies/updateColumn",
      updatePagination: "currencies/updatePagination",
      showCurrency: "currencies/show",
      empty: "currencies/empty",
      delete: "currencies/destroy",
      refresh: "currencies/refreshData",
    }),
    fetchData() {
      const query = { ...this.filter, ...this.pagination, ...this.sort };
      if (!this.loadingData) {
        this.loadingData = true;
        this.getCurrencies(query)
          .then((response) => {
            this.loadingData = false;
          })
          .catch((error) => {
            this.loadingData = false;
          });
      }
    },
    beforeClose(done) {
      this.empty();
      this.refreshData();
      done();
    },
    refreshData() {
      this.refresh().then(() => {
        this.filterForm = JSON.parse(JSON.stringify(this.filter));
      });
    },
    async edit(model) {
      await this.showCurrency(model.id).then(() => {
        this.drawer.create.status = true;
      });
    },
    destroy(model) {
      this.delete(model.id)
        .then((res) => {
          this.$alert(res);
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
